import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../axios/utils";

const cancelTokenHandlerObject = {
  getSupportTickets: createCancelTokenHandler('getSupportTickets'),
  getOrganizationTickets: createCancelTokenHandler('getOrganizationTickets')
}

// support tickets requests

export function getSupportTickets (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/ticketing/support${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSupportTickets'].handleRequestCancellation().token
  })
}

export function getSupportTicket (id) {

  return axios({
    url: `v1/admin/ticketing/support/${id}`,
    method: 'get'
  })
}

export function getAttachment (url) {

  return axios({
    url: `v1${url}`,
    method: 'get'
  })
}

export function insertSupportTicket (payload) {

  return axios({
    url: 'v1/admin/ticketing/support',
    data: payload,
    method: 'post'
  })
}

export function sendMessageSupportTicket (id, payload) {

  return axios({
    url: `v1/admin/ticketing/support/${id}/messages`,
    data: payload,
    method: 'post'
  })
}

export function sendSupportTicketToService (id) {

  return axios({
    url: `v1/admin/ticketing/support/${id}/send`,
    method: 'post'
  })
}

// organization tickets requests

export function getOrganizationTickets (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/ticketing/organizational${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getOrganizationTickets'].handleRequestCancellation().token
  })
}

export function getOrganizationTicket (id) {

  return axios({
    url: `v1/admin/ticketing/organizational/${id}`,
    method: 'get'
  })
}

export function insertOrganizationTicket (payload) {

  return axios({
    url: 'v1/admin/ticketing/organizational',
    data: payload,
    method: 'post'
  })
}

export function sendMessageOrganizationTicket (id, payload) {

  return axios({
    url: `v1/admin/ticketing/organizational/${id}/messages`,
    data: payload,
    method: 'post'
  })
}

export function sendOrganizationTicketToService (id) {

  return axios({
    url: `v1/admin/ticketing/organizational/${id}/send`,
    method: 'post'
  })
}
